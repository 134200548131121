export const API_ACCESS_TOKEN = "Ezetj4whWp4XvhhkJ7CBtQWa2cEjUMyl";
export const DEVICE_TYPE = "web";
export const DEVICE_LANGUAGE = "en";
export const APP_IDENTIFIER = "we-liveapp.com";

export const APP_VERSION = "3.0.0";

export const API_BASE_URL = "https://api.we-liveapp.com"
export const API_AUTH = "v1/auth"

export const SPI_BASE_URL = "https://spi.we-liveapp.com"
export const SPI_SIGNUP_URL = "api/v1/auth/signup"
export const SPI_SIGNIN_URL = "api/v1/auth/signin"
export const API_GET_TOKEN = "api/v1/token_passthrough/get-token"
export const REDIRECTION_URL = "https://app.sugarbook.me/redirect_handler?url=/&from_cta=welive&token="
export const COOKIE_USER_DATA = "sb-pwa-cookie-user-data";
export const COOKIE_API_TOKEN = "sb-pwa-cookie-api-token";

export const GOOGLE_RECAPTCHA_V3_KEY = "6LdO1DsoAAAAAOPdndtuT9GxohDUYIN2EH96JU_u";

export const API_2_CONFIG_HEADERS = {
  headers: {
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${APP_VERSION}`,
    "SB-Language": `${DEVICE_LANGUAGE}`,
    Accept: "application/json",
    "display-mode": "browser",
    platform: `web`,
  },
};