
import { COOKIE_USER_DATA } from '../../const';

const getDefaultState = () => {
  return {
    loggedUser: {},
    justLoggedIn: false,
  }
}

const state = getDefaultState();

const getters = {
    loggedUser: state => {
        return state.loggedUser;
    },
    justLoggedIn: state => {
        return state.justLoggedIn;
    }
};

const mutations = {
    updateLoggedUser: (state, payload) => {
        state.loggedUser = payload;
    },
    updateJustLoggedIn: (state, payoad) => {
        state.justLoggedIn = payoad
    }
};

const actions = {
    async asyncInitializeLoggedUser(context) {
        let userDataCookie = localStorage.getItem(COOKIE_USER_DATA) || '{}';
        userDataCookie = JSON.parse(userDataCookie);
        await context.commit('updateLoggedUser', userDataCookie);
    },

    initializeLoggedUser: context => {
        let userDataCookie = localStorage.getItem(COOKIE_USER_DATA) || '{}';
        userDataCookie = JSON.parse(userDataCookie);
        context.commit('updateLoggedUser', userDataCookie);
    },
    updateLoggedUser: (context, payload) => {

        let userDataJson = JSON.stringify(payload);

        localStorage.setItem(COOKIE_USER_DATA, userDataJson);
        context.commit('updateLoggedUser', payload);
    },
    clearLoggedUser: context => {
        localStorage.setItem(COOKIE_USER_DATA, null);
        context.commit('updateLoggedUser', JSON.parse('{}'));
    },
    updateJustLoggedIn: (context) => {
        context.commit('updateJustLoggedIn', true)

        setTimeout(function() {
            context.commit('updateJustLoggedIn', false)
        }.bind(context), 500)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
