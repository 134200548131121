import { COOKIE_API_TOKEN } from '../../const';

const getDefaultState = () => {
  return {
    apiToken: ''
  }
}

const state = getDefaultState();

const getters = {
    apiToken: state => {
        return state.apiToken;
    }
};

const mutations = {

    resetToDefaultState: (state) => {
        Object.assign(state, getDefaultState())
    },

    updateApiToken: (state, payload) => {
        state.apiToken = payload;
    }
};

const actions = {

  resetToDefaultState: (context) => {
    context.commit('resetToDefaultState')
  },

    async initializeApiTokenPromise(context) {
        let apiTokenCookie = localStorage.getItem(COOKIE_API_TOKEN) || "";
        context.commit('updateApiToken', apiTokenCookie);
        return true
    },

    initializeApiToken: context => {
        let apiTokenCookie = localStorage.getItem(COOKIE_API_TOKEN) || "";
        context.commit('updateApiToken', apiTokenCookie);
    },
    updateApiToken: (context, payload) => {
        localStorage.setItem(COOKIE_API_TOKEN, payload);
        context.commit('updateApiToken', payload);
    },
    handleApiTokenByStatusCode: (context, payload) => {
        let status = payload.status;
        let token = localStorage.getItem(COOKIE_API_TOKEN) || "";

        if (status === 0) { // successful status: token to be update
            token = payload.token;
        } else if (status === -1) { // generic failure status

        } else if (status === -2) { // invalid token status
            token = "";
        }

        localStorage.setItem(COOKIE_API_TOKEN, token);
        context.commit('updateApiToken', token);
    },
    handleApiTokenInHeaders: (context, newToken) => {
        let token = localStorage.getItem(COOKIE_API_TOKEN) || "";
        token = newToken;

        localStorage.setItem(COOKIE_API_TOKEN, token);
        context.commit('updateApiToken', token);
    },
    clearApiToken: context => {
        localStorage.setItem(COOKIE_API_TOKEN, null);
        context.commit('updateApiToken', '');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
