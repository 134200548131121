<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ChooseGender v-if="selectedGender == null & selectedInterest == null" />
        <SignUpForm v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChooseGender from '@/components/ChooseGender.vue'
import SignUpForm from '@/components/SignUpForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ChooseGender, SignUpForm },
  computed: {
    ...mapGetters({
      selectedGender: 'account/selectedGender',
      selectedInterest: 'account/selectedInterest'
    })
  }
}
</script>