const getDefaultState = () => {
 return {
    snackbar: {
        show: false,
        status: '',
        message: ''
    }
  }
}

const state = getDefaultState();

const getters = {
    snackbar: state => {
        return state.snackbar;
    }
};

const mutations = {
    updateStatus: (state, payload) => {
        state.snackbar.show = payload.show;
        state.snackbar.status = payload.status;
        state.snackbar.message = payload.message;
    },
};

const actions = {
  updateStatus (context, payload) {
    context.commit('updateStatus', payload);
  },
  dismissSnackbar (context) {
    context.commit('updateStatus', { show: false, status: '', message: '' });
  }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
