import { SPI_BASE_URL, API_GET_TOKEN } from "../../const"

import * as APIHelperMixin from "../../mixins/APIHelper.js"
const APIHelper = APIHelperMixin.default.methods

const getDefaultState = () => {
  return {
    passthroughToken: null
  }
}

const state = getDefaultState();

const getters = {
  passthroughToken: (state) => {
    return state.passthroughToken
  }
};

const mutations = {
  setPassthroughToken: (state, payload) => {
    state.passthroughToken = payload
  }
};

const actions = {
  setPassthroughToken(context, payload) {
    context.commit('setPassthroughToken', payload)
  },
  async getPassthroughToken(context) {
    let url = `${SPI_BASE_URL}/${API_GET_TOKEN}`

    try {
      let response = await APIHelper.getAxiosResponse(context, 'GET', url, {}, true);
      let data = response.data

      if (data.status >= 0) {
        context.commit('setPassthroughToken', data.data.token)
      }
    }
    catch(error) {
      // console.log(error)
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
