import axios from 'axios';

import {
  API_ACCESS_TOKEN,
  DEVICE_TYPE,
  APP_IDENTIFIER,
  APP_VERSION,
  API_2_CONFIG_HEADERS
} from '../const';

export default {
  methods: {

    getAPIHeadersForComponent(apiToken) {

      var config = {
        headers: {
          'api-access-token': API_ACCESS_TOKEN,
          'device-type': DEVICE_TYPE,
          'app-identifier': APP_IDENTIFIER,
          'app-version': APP_VERSION
        }
      };

      if (apiToken.length >= 0) {
        config.headers["token"] = apiToken;
      }

      return config;
    },

    getAPIHeaders(context, auth = false) {
        return this.getAPISBCOMHeaders(context, auth);
    },

    handleUnauthenticatedError(context, error) {
      if (error.response) {
        if (error.response.status) {
          if ((error.response.status == 401) || (error.response.status == 403)) {
            context.dispatch('apiToken/handleApiTokenByStatusCode', { status: -2 }, { root: true });
          }
        }
      }
    },

    // Drop in header replacments for api.sugarbook.com
    getAPISBCOMHeaders(context, auth = false) {

      var config = {
        headers: {
          'api-access-token': API_ACCESS_TOKEN,
          'device-type': DEVICE_TYPE,
          'app-identifier': APP_IDENTIFIER,
          'app-version': APP_VERSION
        }
      };

      if (auth) {
        let token = context.rootGetters['apiToken/apiToken'];
        config.headers["token"] = `${token}`;
      }

      return config;
    },

    getParamURL(url, params) {
      var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

      if (queryString.length > 0) {
        return url + "?" + queryString;
      }
      return url;
    },

    getAPISBCOMAxiosResponse(context, method, url, params, auth = false) {
      var config = this.getAPISBCOMHeaders(context, auth);

      let token = context.rootGetters['apiToken/apiToken'];
      config.headers["Authorization"] = `${token}`;

      //console.log(config)

      switch(method) {
        case 'GET': {
          let parameterized_url = this.getParamURL(url, params);

          return axios.get(parameterized_url, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
            });
        }

        case 'POST': {
          return axios.post(url, params, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
            });
        }

        case 'DELETE': {
          return axios.delete(url, params, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
          });
        }
      }
    },

    getAxiosResponse(context, method, url, params, auth = false, json = false) {
      var config = API_2_CONFIG_HEADERS;

      if (auth) {
        let token = null

        if (Object.prototype.hasOwnProperty.call(context, 'rootGetters')) {
          token = context.rootGetters['apiToken/apiToken'];
        } else if (Object.prototype.hasOwnProperty.call(params, 'getters')) {
          token = context.getters['apiToken/apiToken'];
        }

        config.headers["Authorization"] = `${token}`;
      }

      if (json) {
        config.headers["Content-Type"] = "application/json";
      }

      // console.log(config.headers)

      switch(method) {
        case 'GET': {
          if (Object.prototype.hasOwnProperty.call(params, 'getParameters')) {
            url = this.getUrlRouteParameter(url, params.getParameters)
            delete params.getParameters
          }

          let parameterized_url = this.getParamURL(url, params);

          return axios.get(parameterized_url, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
            });
        }

        case 'POST': {
          if (Object.prototype.hasOwnProperty.call(params, 'getParameters')) {
            url = this.getUrlRouteParameter(url, params.getParameters)
            delete params.getParameters
          }
          
          return axios.post(url, params, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
            });
        }

        case 'DELETE': {
          return axios.delete(url, params, config)
            .then(result => { return result; })
            .catch(error => { 
              this.handleUnauthenticatedError(context, error)
              throw error;
          });
        }
      }

    }
  }
}