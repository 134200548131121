import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { DEVICE_TYPE } from '../const';

axios.interceptors.request.use((config) => {
    const displayModes = ['fullscreen', 'standalone', 'minimal-ui', 'browser'];

    displayModes.forEach((item) => {
      if (window.matchMedia('(display-mode:' + item  + ')').matches) {
        config.headers['display-mode'] = item;
      }
    });

    config.headers.platform = DEVICE_TYPE;
    
    return config;
});

Vue.use(VueAxios, axios);
