import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import snackbar from './modules/snackbar'
import loggedUser from './modules/loggedUser'
import apiToken from './modules/apiToken'
import redirection from './modules/redirection'
import route from './modules/route'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    snackbar,
    loggedUser,
    apiToken,
    redirection,
    route
  }
})
