<template>
  <v-container fluid mt-6 px-6>
    <v-row>
      <!-- gender selection -->
      <v-col cols="12">
        <p class="gender-option-title">{{ gender.title }}</p>
        <!-- man button -->
        <v-btn depressed block rounded outlined
        :ripple="false"
        height="45px"
        class="text-capitalize all-rounded-button"
        :class="selectedGender == 'man' ? 'button-selected' : 'button-unselected'"
        @click="selectMan">{{ gender.option1 }}</v-btn>

        <!-- woman button -->
        <v-btn depressed block rounded outlined
        :ripple="false"
        height="45px"
        class="text-capitalize all-rounded-button mt-3"
        :class="selectedGender == 'woman' ? 'button-selected' : 'button-unselected'"
        @click="selectWoman">{{ gender.option2 }}</v-btn>
      </v-col>
    </v-row>

    <v-row v-show="selectedGender.length > 0">
      <!-- gender selection -->
      <v-col cols="12">
        <p class="gender-option-title">{{ lookingFor.title }}</p>
        <!-- man button -->
        <v-btn depressed block rounded outlined
        :ripple="false"
        height="45px"
        class="text-capitalize all-rounded-button"
        :class="selectedOption == 1 ? 'button-selected' : 'button-unselected'"
        @click="selectInterestOption1">{{ lookingFor.option1 }}</v-btn>

        <!-- woman button -->
        <v-btn depressed block rounded outlined
        :ripple="false"
        height="45px"
        class="text-capitalize all-rounded-button mt-3"
        :class="selectedOption == 2 ? 'button-selected' : 'button-unselected'"
        @click="selectInterestOption2">{{ lookingFor.option2 }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- continue button -->
        <v-btn depressed block rounded
        v-show="selectedGender.length !== 0 && selectedInterest.length !== 0"
        :ripple="false"
        height="45px"
        color="#FFFFFF"
        class="continue-button text-capitalize mt-12"
        @click="continueToSignup">{{ continueButton.text }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      gender: {
        title: this.$t('signup.i_am_a'),
        option1: this.$t('signup.man'),
        option2: this.$t('signup.woman')
      },
      lookingFor: {
        title: this.$t('signup.looking_for'),
        option1: this.$t('signup.attractive_woman'),
        option2: this.$t('signup.successful_lady')
      },
      continueButton: {
        text: this.$t('signup.continue')
      },
      selectedGender: '',
      selectedInterest: '',
      selectedOption: 0,
    };
  },

  methods: {
    ...mapActions({
      setSelectedRole: 'account/setSelectedRole'
    }),
    selectMan() {
      this.selectedGender = 'man'

      this.lookingFor.option1 = this.$t('signup.attractive_woman')
      this.lookingFor.option2 = this.$t('signup.successful_lady')

      // Reset looking for button
      this.selectedOption = 0;
    },
    selectWoman() {
      this.selectedGender = 'woman'

      this.lookingFor.option1 = this.$t('signup.successful_gentleman')
      this.lookingFor.option2 = this.$t('signup.attractive_man')

      // Reset looking for button
      this.selectedOption = 0;
    },
    selectInterestOption1() {
      this.selectedOption = 1
      if (this.selectedGender == 'man') {
        this.selectedInterest = 'sugarbaby'
      } else {
        this.selectedInterest = 'sugardaddy'
      }
    },
    selectInterestOption2() {
      this.selectedOption = 2
      if (this.selectedGender == 'man') {
        this.selectedInterest = 'sugardaddy'
      } else {
        this.selectedInterest = 'sugarbaby'
      }
    },
    continueToSignup() {
      this.setSelectedRole({ gender: this.selectedGender, interest: this.selectedInterest })
    }
  },
}
</script>

<style scoped>
.gender-option-title {
  margin-bottom: 25px;
  color: #333333;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 0 0 2px 0 rgba(0,0,0,0.5);
}
.all-rounded-button {
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: normal;
  background-color: rgba(0, 0, 0, 0);
}
.button-selected {
  border: 3px solid #4B17C5;
  color: #4B17C5;
}
.button-unselected {
  border: 1px solid #CCCCCC;
  color: #333333;
}
.continue-button {
  margin-top: 15px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
  background: linear-gradient(153.4deg, #A496FF -5.9%, #00095A 83.31%);
  color: #fff !important;
}
</style>
