const getDefaultState = () => {
  return {
    appImmortalRoutes: [],
    redirectAfterLoginPath: null,
    justLoggedOut: false,
    persistentQueries: {},
  };
};

const state = getDefaultState();

const getters = {
  appImmortalRoutes: (state) => {
    return state.appImmortalRoutes;
  },
  redirectAfterLoginPath: (state) => {
    return state.redirectAfterLoginPath;
  },
  justLoggedOut: (state) => {
    return state.justLoggedOut;
  },

  persistentQueries: (state) => {
    return state.persistentQueries;
  },
};

const mutations = {
  resetToDefaultState: (state) => {
    Object.assign(state, getDefaultState());
  },

  updateImmortalRoutes: (state, payload) => {
    var index = state.appImmortalRoutes.indexOf(payload.route);
    if (index == -1) {
      // if not found
      state.appImmortalRoutes.push(payload.route);
    }
  },
  clearImmortalRoutes: (state) => {
    state.appImmortalRoutes = [];
  },
  setRedirectAfterLoginPath: (state, payload) => {
    console.log("setRedirectAfterLoginPath");
    state.redirectAfterLoginPath = payload;
  },
  clearRedirectAfterLoginPath: (state) => {
    console.log("clearRedirectAfterLoginPath");
    state.redirectAfterLoginPath = null;
  },
  setJustLoggedOut: (state, payload) => {
    console.log("setJustLoggedOut");
    state.justLoggedOut = payload;
  },

  setPersistentQueries: (state, payload) => {
    state.persistentQueries = payload;
  },
};

const actions = {
  resetToDefaultState: (context) => {
    context.commit("resetToDefaultState");
  },

  updateImmortalRoutes: (context, payload) => {
    context.commit("updateImmortalRoutes", payload);
  },
  clearImmortalRoutes: (context) => {
    context.commit("clearImmortalRoutes");
  },
  setRedirectAfterLoginPath: (context, payload) => {
    context.commit("setRedirectAfterLoginPath", payload);
  },
  async clearRedirectAfterLoginPath(context) {
    context.commit("clearRedirectAfterLoginPath");
  },
  setJustLoggedOut(context, payload) {
    context.commit("setJustLoggedOut", payload);
  },

  setPersistentQueries(context, payload) {
    context.commit("setPersistentQueries", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
