<template>
  <v-container fluid mt-6 px-6>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" v-model="valid">
          <!-- email text field -->
          <v-text-field required
          :loading="inLoadingState"
          :readonly="inLoadingState"
          :error="email.error"
          :rules="email.rules"
          v-model="email.input"
          color="#00095A"
          type="email"
          class="text-input text-input-title">
            <template v-slot:label>
              <span class="text-input text-input-placeholder">{{ email.label }}</span>
            </template>
          </v-text-field>

          <!-- password text field -->
          <v-text-field required
          :loading="inLoadingState"
          :readonly="inLoadingState"
          :error="password.error"
          :rules="password.rules"
          v-model="password.input"
          color="#00095A"
          class="text-input text-input-title"
          :append-icon="password.show ? 'visibility' : 'visibility_off'"
          :type="password.show ? 'text' : 'password'"
          @click:append="password.show = !password.show">
            <template v-slot:label>
              <span class="text-input text-input-placeholder">{{ password.label }}</span>
            </template>
          </v-text-field>

          <!-- login button -->
          <v-btn block rounded
          :ripple="false"
          height="45px"
          :disabled=isDisabled
          :loading="inLoadingState"
          class="text-capitalize signup-button white-btn-text-color"
          :class="getSBBtnRedColorClass"
          @click="logIn">{{ $t('home.hero_login') }}</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { GOOGLE_RECAPTCHA_V3_KEY } from '@/const';

Vue.use(VueReCaptcha, { siteKey: GOOGLE_RECAPTCHA_V3_KEY });

export default {
  data() {
    return {
      email: {
        input: '',
        label: this.$t('signup.email_address'),
        error: false,
        rules: [
          v => !!v || this.$t('signup.error_required', { field: this.$t('signup.email_address') }),
          v => /.+@.+\.+.{2,}/.test(v) || this.$t('signup.error_email_valid'),
        ]
      },
      password: {
        input: '',
        label: this.$t('signup.password'),
        show: false,
        error: false,
        rules: [
          v => !!v || this.$t('signup.error_required', { field: this.$t('signup.password') })
        ]
      },
      inLoadingState: false,
      valid: true,
    };
  },
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar',
      loggedUser: 'loggedUser/loggedUser',
    }),
    isDisabled() {
      // disable button when there is invalid value in v-form
      return !this.valid;
    },
    getSBBtnRedColorClass() {
      if (this.isDisabled) {
        return 'sb-red-btn-text-color-disabled'
      } else {
        return 'sb-red-btn-text-color-enabled'
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'account/login',
      loginV2: 'account/signinV2'
    }),
    async logIn() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // clear input errors
      this.email.error = false;
      this.password.error = false;

      // put login form in to loading state
      this.inLoadingState = true;
      var inputData = {
          auth_method: 'email_password',
          email: this.email.input,
          password: this.password.input,
          gre_token: token
      };

      await this.loginV2({ inputData: inputData, axios: this.axios }).then(() => {
        this.redirect();
      }).catch(() => {
        this.inLoadingState = false;
      });
    },
    redirect() {
      this.$router.push({ name: 'redirect' })
    }
  }
}
</script>

<style scoped>
.signup-form-main-container {
  margin-bottom: 2px !important;
  margin-top: 10px !important;
}
.create-new-account {
  margin-bottom: 23px;
  color: #333333;
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
}
.text-input {
  font-family: Lato;
  font-size: 18px;
}
.text-input-title {
  color: #333333;
}
.text-input-placeholder {
  color: #7C7C7C;
}
.signup-button {
  margin-top: 15px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
  background: linear-gradient(153.4deg, #A496FF -5.9%, #00095A 83.31%);
}
.sb-red-btn-text-color-enabled {
  color: #fff !important;
  caret-color: #fff !important;
}
.sb-red-btn-text-color-disabled {
  color: #9f9f9f !important;
  caret-color: #9f9f9f !important;
}
</style>
