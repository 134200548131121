<template>
  <v-snackbar
    top
    class="status-snackbar"
    v-model="snackbar.show"
    :color="snackbar.status"
    :timeout="3000"
  >
    {{ snackbar.message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="dismissSnackbar"
      >
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar'
    }),
    snackbar: {
      get() {
        return this.$store.getters['snackbar/snackbar'];
      },
      set(state) {
        this.$store.dispatch('snackbar/updateStatus', { show: state, status: '', message: '' });
      }
    },
  },
  methods: {
    ...mapActions({
      dismissSnackbar: 'snackbar/dismissSnackbar'
    })
  }
}
</script>

<style scoped>
.status-snackbar {
  padding-bottom: 12px;
}
</style>
