import { API_BASE_URL, API_AUTH, SPI_BASE_URL, SPI_SIGNUP_URL, SPI_SIGNIN_URL } from "../../const"

import * as APIHelperMixin from "../../mixins/APIHelper.js"
const APIHelper = APIHelperMixin.default.methods

const getDefaultState = () => {
  return {
    email: "",
    isAuthenticated: false,
    initialPlatform: "pwa",
    selectedGender: null,
    selectedInterest: null,
    selectedWantToBe: null,
  }
}

const state = getDefaultState()

const getters = {
  email: (state) => {
    return state.email
  },
  isAuthenticated: (state) => {
    return state.isAuthenticated
  },
  initialPlatform: (state) => {
    return state.initialPlatform
  },
  selectedGender: (state) => {
    return state.selectedGender
  },
  selectedInterest: (state) => {
    return state.selectedInterest
  },
  selectedWantToBe: (state) => {
    return state.selectedWantToBe
  }
}

const mutations = {
  setEmail: (state, payload) => {
    state.email = payload
  },
  setIsAuthenticated: (state, payload) => {
    state.isAuthenticated = payload
  },
  setInitialPlatform: (state, payload) => {
    state.initialPlatform = payload
  },
  setSelectedRole: (state, payload)  => {
    state.selectedGender = payload.gender
    state.selectedInterest = payload.interest

    switch (`${state.selectedGender}-${state.selectedInterest}`) {
      case "man-sugarbaby":
        state.selectedWantToBe = "sugardaddy";
        break;
      case "man-sugardaddy":
        state.selectedWantToBe = "sugarbaby";
        break;
      case "woman-sugardaddy":
        state.selectedWantToBe = "sugarbaby";
        break;
      case "woman-sugarbaby":
        state.selectedWantToBe = "sugardaddy";
        break;
      default:
        break;
    }
  }
}

const actions = {
  setEmail(context, payload) {
    context.commit("setEmail", payload)
  },
  async signup(context, payload) {
    // getting axios instance
    let axios = payload.axios

    // preparing api path
    const apiUri = `${API_BASE_URL}/${API_AUTH}`

    const headers = APIHelper.getAPIHeaders(context, false)

    // preparing payload
    var signupInfo = {
      action: "signup",
    }

    let persistentQueries = {
      utm_data: JSON.stringify(context.rootGetters["route/persistentQueries"])
    }

    let formData = {
      ...signupInfo,
      ...payload.inputData,
      ...persistentQueries,
    }

    // making the call
    let call = new Promise((resolve, reject) => {
      axios
        .post(apiUri, formData, headers)
        .then((response) => {
          // get data from the response
          let data = response.data
          // console.log(data)

          if (data.status === 0) {
            // set authentication status as true
            context.commit("setIsAuthenticated", true)

            // set user's new token
            let newApiToken = data.data.token
            context.dispatch("apiToken/updateApiToken", newApiToken, { root: true })

            // set logged user data
            let userProfile = data.data.user
            let userData = { ...userProfile }
            context.dispatch("loggedUser/updateLoggedUser", userData, { root: true })

            resolve()
          } else {
            context.dispatch(
              "snackbar/updateStatus",
              {
                show: true,
                status: "error",
                message: 'Something went wrong. Please try again.',
              },
              {
                root: true,
              }
            )
          }
        })
        .catch((error) => {
          let message = error.response.data.message
          context.dispatch(
            "snackbar/updateStatus",
            {
              show: true,
              status: "error",
              message: message,
            },
            {
              root: true,
            }
          )
          reject(error)
        })
        .finally(() => {})
    })

    await call
      .then(() => {
        Promise.resolve(true)
      })
      .catch((error) => {
        Promise.reject(error)
      })
  },
  async login(context, payload) {
    // getting axios instance
    let axios = payload.axios

    // preparing api path
    const apiUri = `${API_BASE_URL}/${API_AUTH}`

    const headers = APIHelper.getAPIHeaders(context, false)

    // preparing payload
    var signupInfo = {
      action: "login",
    }

    let persistentQueries = {
      utm_data: JSON.stringify(context.rootGetters["route/persistentQueries"])
    }

    let formData = {
      ...signupInfo,
      ...payload.inputData,
      ...persistentQueries,
    }

    // making the call
    let call = new Promise((resolve, reject) => {
      axios
        .post(apiUri, formData, headers)
        .then((response) => {
          // get data from the response
          let data = response.data
          // console.log(data)

          if (data.status === 0) {
            // set authentication status as true
            context.commit("setIsAuthenticated", true)

            // set user's new token
            let newApiToken = data.data.token
            context.dispatch("apiToken/updateApiToken", newApiToken, { root: true })

            // set logged user data
            let userProfile = data.data.user
            let userData = { ...userProfile }
            context.dispatch("loggedUser/updateLoggedUser", userData, { root: true })

            resolve()
          } else {
            context.dispatch(
              "snackbar/updateStatus",
              {
                show: true,
                status: "error",
                message: 'Something went wrong. Please try again.',
              },
              {
                root: true,
              }
            )
          }
        })
        .catch((error) => {
          let message = error.response.data.message
          context.dispatch(
            "snackbar/updateStatus",
            {
              show: true,
              status: "error",
              message: message,
            },
            {
              root: true,
            }
          )
          reject(error)
        })
        .finally(() => {})
    })

    await call
      .then(() => {
        Promise.resolve(true)
      })
      .catch((error) => {
        Promise.reject(error)
      })
  },
  async signupV2(context, payload) {
    let url = `${SPI_BASE_URL}/${SPI_SIGNUP_URL}`

    const obj = payload.inputData;

    return new Promise((resolve, reject) => {
      APIHelper.getAxiosResponse(context, 'POST', url, obj, true)
        .then((response) => {
          let data = response.data
          resolve(data)
          if (data.status >= 0) {
            let newApiToken = data.data.legacy_token;
            context.dispatch('redirection/setPassthroughToken', newApiToken, { root: true })
          } else if (data.status === -1) {
            context.dispatch(
              "snackbar/updateStatus",
              {
                show: true,
                status: "error",
                message: data.message,
              },
              {
                root: true,
              }
            )
          }
        })
        .catch((error) => {
          context.dispatch(
            "snackbar/updateStatus",
            {
              show: true,
              status: "error",
              message: error.response.data.message,
            },
            {
              root: true,
            }
          )
          reject(error)
        })
    })
  },
  async signinV2(context, payload) {
    let url = `${SPI_BASE_URL}/${SPI_SIGNIN_URL}`

    const obj = payload.inputData;

    return new Promise((resolve, reject) => {
      APIHelper.getAxiosResponse(context, 'POST', url, obj, true)
        .then((response) => {
          let data = response.data
          resolve(data)
          if (data.status >= 0) {
            let newApiToken = data.data.legacy_token;
            context.dispatch('redirection/setPassthroughToken', newApiToken, { root: true })
          } else if (data.status === -1) {
            context.dispatch(
              "snackbar/updateStatus",
              {
                show: true,
                status: "error",
                message: data.message,
              },
              {
                root: true,
              }
            )
          }
        })
        .catch((error) => {
          context.dispatch(
            "snackbar/updateStatus",
            {
              show: true,
              status: "error",
              message: error.response.data.message,
            },
            {
              root: true,
            }
          )
          reject(error)
        })
    })
  },
  deauthenticate(context) {
    context.commit("setIsAuthenticated", false)
  },

  softAuthenticate(context) {
    context.commit("setIsAuthenticated", true)
  },

  resetInitialPlatform(context) {
    context.commit("setInitialPlatform", "pwa")
  },

  setSelectedRole(context, payload) {
    context.commit("setSelectedRole", payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
