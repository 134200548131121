import { render, staticRenderFns } from "./SignUpForm.vue?vue&type=template&id=50401c76&scoped=true&"
import script from "./SignUpForm.vue?vue&type=script&lang=js&"
export * from "./SignUpForm.vue?vue&type=script&lang=js&"
import style0 from "./SignUpForm.vue?vue&type=style&index=0&id=50401c76&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50401c76",
  null
  
)

export default component.exports