<template>
  <v-container fluid mt-6 px-6>
    <v-row>
      <v-col cols="12">
        <p class="create-new-account text-center">{{ this.$t('signup.create_new_account') }}</p>
        <v-form ref="form" v-model="valid">
          <!-- email text field -->
          <v-text-field required :loading="inLoadingState" :readonly="inLoadingState" :error="email.error"
            :rules="email.rules" v-model="email.input" color="#00095A" type="email" class="text-input text-input-title">
            <template v-slot:label>
              <span class="text-input text-input-placeholder">{{ email.label }}</span>
            </template>
          </v-text-field>

          <!-- password text field -->
          <v-text-field required :loading="inLoadingState" :readonly="inLoadingState" :error="password.error"
            :rules="password.rules" v-model="password.input" color="#00095A" class="text-input text-input-title"
            :append-icon="password.show ? 'visibility' : 'visibility_off'" :type="password.show ? 'text' : 'password'"
            @click:append="password.show = !password.show">
            <template v-slot:label>
              <span class="text-input text-input-placeholder">{{ password.label }}</span>
            </template>
          </v-text-field>

          <!-- sign up button -->
          <v-btn block rounded :ripple="false" height="45px" :disabled=isDisabled :loading="inLoadingState"
            class="text-capitalize signup-button white-btn-text-color" :class="getSBBtnRedColorClass" @click="signUp">{{
          $t('signup.cta') }}</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { sha256 } from '../plugins/sha256';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { GOOGLE_RECAPTCHA_V3_KEY } from "../const";

Vue.use(VueReCaptcha, { siteKey: GOOGLE_RECAPTCHA_V3_KEY });

export default {
  data() {
    return {
      email: {
        input: '',
        label: this.$t('signup.email_address'),
        error: false,
        rules: [
          v => !!v || this.$t('signup.error_required', { field: this.$t('signup.email_address') }),
          v => /.+@.+\.+.{2,}/.test(v) || this.$t('signup.error_email_valid'),
        ]
      },
      password: {
        input: '',
        label: this.$t('signup.password'),
        show: false,
        error: false,
        rules: [
          v => !!v || this.$t('signup.error_required', { field: this.$t('signup.password') }),
          v => (v || '').length >= 6 || this.$t('signup.error_password_min', { n: 6 })
        ]
      },
      signUpButton: {
        text: this.$t('signup.cta')
      },
      inLoadingState: false,
      valid: true,
    };
  },
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar',
      gender: 'account/selectedGender',
      lookingFor: 'account/selectedInterest',
      loggedUser: 'loggedUser/loggedUser',
      wantToBe: 'account/selectedWantToBe',
    }),
    isDisabled() {
      // disable button when there is invalid value in v-form
      return !this.valid;
    },
    getSBBtnRedColorClass() {
      if (this.isDisabled) {
        return 'sb-red-btn-text-color-disabled'
      } else {
        return 'sb-red-btn-text-color-enabled'
      }
    }
  },
  methods: {
    ...mapActions({
      signup: 'account/signup',
      signupV2: 'account/signupV2',
      setEmail: 'account/setEmail',
      setPassthroughToken: 'redirection/setPassthroughToken',
    }),
    async signUp() {
      const referer = this.$route.query.referer;
      const utmData = this.$route.query;
      utmData.reference_url = referer || "";

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // clear input errors
      this.email.error = false;
      this.password.error = false;

      // put login form in to loading state
      this.inLoadingState = true;
      var inputData = {
        auth_method: 'email_password',
        email: this.email.input,
        password: this.password.input,
        gender: this.gender,
        wanttobe: this.wantToBe,
        gre_token: token,
        utm_data: utmData,
      };

      this.setEmail(this.email.input);

      await this.signupV2({ inputData: inputData, axios: this.axios }).then(() => {
        this.redirect()
      }).catch(() => {
        this.inLoadingState = false;
      });
    },

    submitSnapchat(e, t, n) {
      if (e.snaptr) return
      var a = e.snaptr = function () { a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments) }
      a.queue = []
      var s = 'script',
        r = t.createElement(s)
      r.async = !0
      r.src = n
      var u = t.getElementsByTagName(s)[0]
      u.parentNode.insertBefore(r, u)
      a('init', 'f6e99e3f-e05d-4bad-9e9a-63767e9be44d', { 'user_email': sha256(`${this.emailAddress}`) });
      a('track', 'SIGN_UP');
    },

    submitTwitter(e, t, n, s, u, a) {
      e.twq || (s = e.twq = function () {
        s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
      }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js',
        a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a))
      s('config', 'ocb1l');
      s('event', 'tw-ocb1l-ocdfb', { email_address: sha256(`${this.emailAddress}`) })
    },

    submitMeta() {
      if (window.fbq) {
        window.fbq("track", "CompleteRegistration", {
          email: sha256(`${this.emailAddress}`),
        });
      }
    },

    async redirect() {
      this.submitSnapchat(window, document, 'https://sc-static.net/scevent.min.js')
      this.submitTwitter(window, document, 'script')
      this.submitMeta()
      await this.$router.push({ name: 'redirect' })
    },
  },
}
</script>

<style scoped>
.signup-form-main-container {
  margin-bottom: 2px !important;
  margin-top: 10px !important;
}

.create-new-account {
  margin-bottom: 23px;
  color: #333333;
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
}

.text-input {
  font-family: Lato;
  font-size: 18px;
}

.text-input-title {
  color: #333333;
}

.text-input-placeholder {
  color: #7C7C7C;
}

.signup-button {
  margin-top: 15px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  background: linear-gradient(153.4deg, #A496FF -5.9%, #00095A 83.31%);
}

.sb-red-btn-text-color-enabled {
  color: #fff !important;
  caret-color: #fff !important;
}

.sb-red-btn-text-color-disabled {
  color: #9f9f9f !important;
  caret-color: #9f9f9f !important;
}
</style>
