<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img src="../assets/loading-background.png"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" justify="center">
        <p class="thank-you mt-n3">{{ $t('redirection.thank_you') }}</p>
        <p class="please-wait" align-center v-html="$t('redirection.please_wait')"></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <div class="loading"><div></div><div></div><div></div><div></div></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="click-here mt-16" @click="redirectToSb()">{{ $t('redirection.click_here') }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { REDIRECTION_URL } from '@/const'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      token: 'redirection/passthroughToken',
      email: 'account/email',
    })
  },
  methods: {
    ...mapActions({
      getPassthroughToken: 'redirection/getPassthroughToken'
    }),

    async redirectToSb() {
      let redirectionUrl = `${REDIRECTION_URL}${this.token}&email=${this.email}`
      window.location.replace(redirectionUrl)
    }
  },
  created() {
    new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
      this.redirectToSb()
    })
  }
}
</script>

<style scoped>
.thank-you {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 37px;
  text-align: center;
  color: #4B17C5;
}
.please-wait {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 15.0775px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.click-here {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11.0775px;
  line-height: 13px;
  text-align: center;
  color: #3A45D2;
}

/* Loading Animation */
.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3A45D2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading div:nth-child(1) {
  left: 8px;
  animation: loading1 0.6s infinite;
}
.loading div:nth-child(2) {
  left: 8px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(3) {
  left: 32px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(4) {
  left: 56px;
  animation: loading3 0.6s infinite;
}
@keyframes loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>