import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

const supportedLang = ['en', 'ja']
const loadedLang = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}
// set different custom header for different endpoint
// use axios interceptors
axios.interceptors.request.use(
  (config) => {
    // rely on other header value due to having different domains (spi.xxx, dev-spi.xxx, etc???)
    // assuming this flag only available on these headers
    if (config.headers['SB-Platform']) {
      config.headers['SB-Language'] = getBackendLocaleKey(i18n.locale)
    } else {
      config.headers['device-language'] = getBackendLocaleKey(i18n.locale)
    }
    return config;
  }, (error) => {
    return Promise.reject(error)
  }
);

export function getBrowserLocale() {
  return window.navigator.userLanguage || window.navigator.language
}

// BE use different locale key, hence need convert FE key -> BE key
export function getBackendLocaleKey(lang) {
  if (lang === 'ja') {
    return 'ja-jp'
  }
  return lang
}

export function loadLanguageAsync(lang) {
  let newLang = lang

  // set language
  if (!newLang) {
    newLang = getBrowserLocale().slice(0, 2)
    if (!supportedLang.includes(newLang)) {
      newLang = supportedLang[0]
    }
  }

  // already loaded
  if (loadedLang.includes(newLang)) {
    return Promise.resolve(setI18nLanguage(newLang))
  }

  // fetch languages
  return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${newLang}.json`).then(
    (messages) => {
      i18n.setLocaleMessage(newLang, messages.default)
      loadedLang.push(newLang)
      return setI18nLanguage(newLang)
    }
  )
}

/**
 * Register custom i18n helpers
 */
const SBi18n = {
  install(Vue) {
    Vue.prototype.genLocaleKey = (str, group = '') => {
      const key = str.replace(/ /g, '_').replace(/[\W]+/g, '').toLowerCase()
      return [
        group,
        key,
      ].filter((n) => n).join('.')
    };
  },
}
Vue.use(SBi18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

/**
 * Init i18n support
 */
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: supportedLang[0],
  messages: loadLocaleMessages()
})
loadLanguageAsync()