import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import "./plugins/axios"

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

  // Add persistent query tracking
  let persistentQueries = store.getters["route/persistentQueries"];

  for (const [key, value] of Object.entries(from.query)) {
    if (key.includes("utm_")) {
      persistentQueries[key] = value;
    }
  }

  let finalQueries = {};

  for (const [key, value] of Object.entries(persistentQueries)) {
    if (!Object.prototype.hasOwnProperty.call(finalQueries, key)) {
      finalQueries[key] = value;
    }
  }

  store.dispatch("route/setPersistentQueries", finalQueries);

  next();
});

router.afterEach(() => {
  // Add persistent query tracking
  let persistentQueries = store.getters["route/persistentQueries"];

  const url = new URL(window.location);
  for (const [key, value] of Object.entries(persistentQueries)) {
    url.searchParams.set(key, value);
  }

  window.history.replaceState({}, "", url);
});