<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      elevation="1"
    >
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img
          alt="WeLIVE Logo"
          class="shrink"
          contain
          src="../src/assets/logo.png"
          transition="scale-transition"
          height="40"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />

      <!-- status snackbar -->
      <StatusSnackbar />
    </v-main>
  </v-app>
</template>

<script>
import StatusSnackbar from './views/StatusSnackbar.vue'

export default {
  name: 'App',
  components: {
    StatusSnackbar
  },
  methods: {
    enableGoogleAnalytics() {
      var googleAnalyticsId = this.loadGtmId() // GTM-XXXXXXX
      if (googleAnalyticsId.length > 0) {
        this.activateGtm(window,document,'script','dataLayer',googleAnalyticsId)
      }
    },
    loadGtmId() {
      var baseUrl = window.location.origin
      switch (baseUrl) {
        case 'https://we-liveapp.com':
          return 'GTM-NQKLNXZ'
        case 'https://welive.world':
          return 'GTM-WFPJ3QP'
        default:
          return ''
      }
    },
    activateGtm(w,d,s,l,i) {
      w[l]=w[l]||[]
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'})
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:''
      j.async=true
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl
      f.parentNode.insertBefore(j,f)
    }
  },
  created() {
    this.enableGoogleAnalytics()
  }
};
</script>
